import { Service } from 'typedi';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import { CashAdvanceOptions } from 'shared/models/cash-advances/cash-advance-options.model';
import { CashAdvanceFee } from 'shared/models/cash-advances/cash-advance-fee.model';
import { CashAdvance } from 'shared/models/cash-advances/cash-advance.model';
import { BaseRepository } from 'app/repositories/base.repository';
import { CarrierOptions } from 'shared/models/cash-advances/carrier-options.model';
import { CashAdvanceSecurityCode } from 'shared/models/cash-advances/cash-advance-security-code.model';
import { NotificationBadgeCounts } from 'pages/payments/types';
import { ResultSet } from 'shared/models/result-set.model';
import { PaymentTabStatus } from 'pages/payments/payment-tab/types';
import { CashAdvanceOptionsV3 } from '@shared/models/cash-advances/cash-advance-options-V3.model';

@Service()
export class FinancialsRepository extends BaseRepository {

  protected baseUrl: string;

  constructor(url?: string) {
    super();
    this.baseUrl = url || apiConfig.financialsAPI;
  }

  public getCashAdvanceOptions(loadNumber: number, bookSeqNum: number): Observable<CashAdvanceOptions> {
    return this.get(`/Loads/${loadNumber}/CashAdvanceOptions?bookSeqNum=${bookSeqNum}`, { 'X-Ms-Version': 2.0 }).map(json => new CashAdvanceOptions(json));
  }

  public getCashAdvanceOptionsV3(loadNumber: number, bookSeqNum: number): Observable<CashAdvanceOptionsV3> {
    return this.get(`/Loads/${loadNumber}/CashAdvanceOptions?bookSeqNum=${bookSeqNum}`, { 'X-Ms-Version': 3.0 }).map(json => new CashAdvanceOptionsV3(json.cashAdvanceOptions));
  }

  public getCarrierOptions(carrierCode: string): Observable<CarrierOptions> {
    return this.get(`/Carrier/${carrierCode}/Options`).map(json => new CarrierOptions(json));
  }

  public getCashAdvanceFees(loadNumber: number, currencyCode: string, advanceAmount: number, sourceType: number, bookSeqNum: number, promotionId?: number): Observable<CashAdvanceFee> {
    let url = `/Loads/${loadNumber}/CashAdvanceFee?bookSeqNum=${bookSeqNum}&currencyCode=${currencyCode}&advanceAmount=${advanceAmount}&sourceType=${sourceType}`;

    if (promotionId) {
      url += `&promotionId=${promotionId}`;
    }

    return this.get(url, {
      'X-Ms-Version': 2.0,
    }).map(json => new CashAdvanceFee(json));
  }

  public getCashAdvanceFeesV3(
    loadNumber: number,
    bookSeqNum: number,
    currencyCode: string,
    advanceAmount: number,
    cashAdvanceSourceType: number,
  ): Observable<any> {
    const params = new URLSearchParams({
      bookSeqNum: bookSeqNum.toString(),
      currencyCode,
      advanceAmount: advanceAmount.toString(),
      cashAdvanceSourceType: cashAdvanceSourceType.toString(),
    });
  
    const url = `/Loads/${loadNumber}/CashAdvanceFee?${params.toString()}`;
  
    return this.get(url, {
      'X-Ms-Version': 3.0,
    }).map(json => new CashAdvanceFee(json));
  }

  public getCashAdvanceHistory(loadNumber: number, bookSeqNum: number): Observable<CashAdvance[]> {
    return this.get(`/Loads/${loadNumber}/CashAdvances?bookSeqNum=${bookSeqNum}`, { 'X-Ms-Version': 2.0 }).map((items: CashAdvanceJSON[]) =>
      items
        .filter(item => item.totalAmount > 0)
        .map(item => new CashAdvance(item))
        .sort((a, b) => new Date(b.requestedDate).getTime() - new Date(a.requestedDate).getTime())
    );
  }

  public getCashAdvanceHistoryV3(loadNumber: number, bookSeqNum: number): Observable<CashAdvance[]> {
    return this.get(`/Loads/${loadNumber}/CashAdvanceOptions?bookSeqNum=${bookSeqNum}`, { 'X-Ms-Version': 3.0 }).map((items) =>
      items.cashAdvances
        .filter(item => item.amount > 0)
        .map(item => new CashAdvance(item))
        .sort((a, b) => new Date(b.createdDateTimeUtc).getTime() - new Date(a.createdDateTimeUtc).getTime())
    );
  }

  public createCashAdvance(loadNumber: number, criteria: CashAdvance): Observable<CashAdvance> {
    const data = criteria.toJson();

    return this.post(`/Loads/${loadNumber}/CashAdvances`, data).map(response => new CashAdvance(response));
  }

  public createCashAdvanceV2(loadNumber: number, bookSeqNum: number, data: CashAdvanceCreateModel): Observable<CashAdvance> {
    return this.post(`/Loads/${loadNumber}/CashAdvances?bookSeqNum=${bookSeqNum}`, data, { 'X-Ms-Version': 2.0 }).map(response => new CashAdvance(response));
  }

  public createCashAdvanceV3(loadNumber: number, bookSeqNum: number, data: CashAdvanceCreateModel): Observable<CashAdvance> {
    return this.post(`/Loads/${loadNumber}/CashAdvances?bookSeqNum=${bookSeqNum}`, data, { 'X-Ms-Version': 3.0 }).map(response => new CashAdvance(response));
  }

  public getCarrierPaymentOption(carrierCode: string): Observable<PaymentOption> {
    return this.get(`/Carrier/${carrierCode}/PaymentOptions`)
      .map(result => result);
  }

  public getCarrierQuickPayRate(carrierCode: string): Observable<number> {
    return this.get(`/Carrier/${carrierCode}/PaymentOptions/QuickPayRate`)
      .map(result => result);
  }

  public putPaymentOption(carrierCode: string, updatePaymentOption: UpdatePaymentOption): Observable<any> {
    return this.put(`/Carrier/${carrierCode}/PaymentOptions`, updatePaymentOption);
  }

  public getSecurityCodeNumber(loadNumber: number, cashAdvanceID: number, bookSeqNum: number, authCode: string): Observable<CashAdvanceSecurityCode> {
    return this.get(`/Loads/${loadNumber}/CashAdvances/${cashAdvanceID}/SecurityCode?bookSeqNum=${bookSeqNum}&authCode=${authCode}`, { 'X-Ms-Version': 2.0 }).map(
      json => new CashAdvanceSecurityCode(json)
    );
  }

  public sendMail() {
    return this.post(`/AuthChallenges`, {}, { 'X-Ms-Version': 2.0 });
  }

  public getPaymentCounts(carrierCodes: Array<string>): Observable<NotificationBadgeCounts> {
    // default criteria to retrieve all waiting on documents dockets and return counts
    const criteria = {
      status: PaymentTabStatus.WAITING_ON_DOCUMENTS,
      carrierCodes: carrierCodes,
      sortByColumnName: 'LoadNumber',
      sortOrder: 'ASC',
      pageNumber: 1,
      pageSize: 25
    }

    return this.post(`/PaymentDocket/Search`, criteria)
      .map(response => ({
          waitingOnDocuments: response.total
      }));
  }

  public getPayableDocketSearch(criteria: PageablePaymentsSearchCriteria): Observable<ResultSet<PayableDocket>> {
    return this.post(`/PaymentDocket/Search`, criteria)
      .map(response => {
          return new ResultSet({ results: response.results, totalRecords: response.total });
      });
  }
}
