/**
 * Concurrency Lock
 *
 * Browser level, cross tab implementation of a concurrency lock.
 * This tells the browser that _it_ should not check if that tab
 * should be frozen/disconnected because of "inactivity"
 */
export namespace Locks {
  export const LockId = 'CHR-TAB-SLEEP-LOCK';
}

export const API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const API_XDATE_FORMAT = 'YYYY/MM/DD HH:mm:ssZZ';

export const EM_DASH = '—';

export namespace Http {
  export const GET = 'GET';
  export const PUT = 'PUT';
  export const PATCH = 'PATCH';
  export const POST = 'POST';
  export const DELETE = 'DELETE';
}

export namespace Headers {
  export const AUTHORIZATION = 'Authorization';
  export const CONTENT_TYPE = 'Content-Type';
  export const ACCEPT = 'Accept';
  export const X_DATE = 'X-Date';
  export const API_KEY = 'ApiKey';
  export const X_BEARER_AUTHORIZATION = 'X-Bearer-Authorization';
  export const MO_AUTH = 'Mo-Auth';
  export const MEMBERSHIP_CODE = 'x-membership-code'
}

export namespace MimeType {
  export const IMAGE_TIF = 'image/tif';
  export const APPLICATION_PDF = 'application/pdf';
  export const APPLICATION_OFFICE_SHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  export const APPLICATION_MS_EXCEL = 'application/vnd.ms-excel';
  export const APPLICATION_OFFICE_DOCUMENT = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  export const APPLICATION_MS_WORD = 'application/msword';
  export const TEXT_CSV = 'text/csv';
  export const TEXT_PLAIN = 'text/plain';
  export const IMAGE_JPEG = 'image/jpeg';
  export const APPLICATION_OCTET_STREAM = 'application/octet-stream';
  export const IMAGE_PNG = 'image/png';

  export function getByExtension(extension: string) {
    extension = extension.replace(/\./g, '').toLowerCase();
    switch (extension) {
      case 'tif':
        return MimeType.IMAGE_TIF;
      case 'jpeg':
      case 'jpg':
        return MimeType.IMAGE_JPEG;
      case 'pdf':
        return MimeType.APPLICATION_PDF;
      case 'xlsx':
        return MimeType.APPLICATION_OFFICE_SHEET;
      case 'xls':
        return MimeType.APPLICATION_MS_EXCEL;
      case 'docx':
        return MimeType.APPLICATION_OFFICE_DOCUMENT;
      case 'doc':
        return MimeType.APPLICATION_MS_WORD;
      case 'csv':
        return MimeType.TEXT_CSV;
      case 'txt':
        return MimeType.TEXT_PLAIN;
      default:
        return MimeType.APPLICATION_OCTET_STREAM;
    }
  }
}

// It's possible to see the whole list of moment.js timezones and their aliases here:
// https://github.com/moment/moment-timezone/blob/b58fdcef24fc161eeb3e59bfe5e1a3edcd000529/data/packed/latest.json#L576
export namespace TimeZoneNames {
  export const CST = 'US/Central';
  export const UTC = 'Etc/UTC';
}

/**
 * Medallia form ids. You can find this id in the Medallia iframe or can be provide by Medallia team.
 * @namespace
 */
export namespace MedalliaFormsId {
  export const FEEDBACK_FORM = 37407;
  export const CARRIER_ADVANTAGE_PROGRAM_QUESTIONS_FORM = 33381;
}

/**
 * Events triggered by Medallia form. You'll need credentials to access Medallia documentation.
 * @namespace
 * @see https://docs.medallia.com/digital/digital/index.html#Digital/Digital_Web/Integration_guide/Custom_events/Custom_events_integration_using_custom_HTML.html
 */
export namespace MedalliaEventListeners {
  //Automatic Events
  export const SHOW_FORM_CALLED = 'MDigital_ShowForm_Called';
  export const FORM_DISPLAYED = 'MDigital_Form_Displayed';
  export const INVITE_DISPLAYED = 'MDigital_Invite_Displayed';
  export const INVITE_ACCEPTED = 'MDigital_Invite_Accepted';
  export const INVITE_DECLINED = 'MDigital_Invite_Declined';
  export const INVITE_SKYPED = 'MDigital_Invite_Skipped';
  /** Triggered when user submits the form successfully */
  export const SUBMIT_FEEDBACK = 'MDigital_Submit_Feedback';
  /** Triggered when user clicks cancel button after the form is submitted */
  export const FORM_CLOSE_SUBMITTED = 'MDigital_Form_Close_Submitted';
  /** Triggered when user clicks cancel button before the form is submitted */
  export const FORM_CLOSE_NO_SUBMIT = 'MDigital_Form_Close_No_Submit';
  export const FEEDBACK_BUTTON_CLICKED = 'MDigital_Feedback_Button_Clicked';
  export const CAPTURE_BUTTON_CLICKED = 'MDigital_CaptureButton_Clicked';
  export const CAPTURE_BUTTON_TAKEN = 'MDigital_CaptureButton_Taken';
  export const CAPTURE_BUTTON_CANCEL = 'MDigital_CaptureButton_Cancel';
  //Manual Events
  export const FORM_NEXT_PAGE = 'MDigital_Form_Next_Page';
  export const FORM_BACK_PAGE = 'MDigital_Form_Back_Page';
  export const FORM_THANK_YOU_DISPLAYED = 'MDigital_ThankYou_Displayed';
  export const FORM_THANK_YOU_CLOSE = 'MDigital_ThankYou_Close';
}

/**
 * Events triggered by window object (DOM). Important: Add if necessary
 * @namespace
 * @see https://developer.mozilla.org/en-US/docs/Web/Events
 */
export namespace WindowEventListeners {
  export const RESIZE = 'resize';
  export const CHANGE = 'change';
}

export namespace LoaderNames {
  export const Main = 'main';
  export const BinFormModal = 'bin-form-modal';
  export const BinFormModalV2 = 'bin-form-modal-ver-2';
  export const BinConfirmationModalV2Reloads = 'bin-confirmation-modal-ver-2-reloads';
  export const FindLoadsVer2LoadDetails = 'find-loads-ver-2-load-details';
  export const FindLoadsVer2AllReloads = 'find-loads-ver-2-all-reloads';
  export const FindLoadsVer2ReloadDetails = 'find-loads-ver-2-reload-details';
  export const FindLoadsVer2OffersModal = 'find-loads-ver-2-offers-modal';
  export const FindLoadsVer2LoadDetailReloads = 'find-loads-ver-2-load-reloads';
  export const DriverSelectField = 'driver-select-field';
  export const SuggestedLoads = 'suggested-loads';
  export const CashAdvanceFee = 'cash-advance-fee';
  export const CashAdvancePasscode = 'cash-advance-passcode';

  export const MyLoadsSearch = 'my-loads-ver-2-search';
}

export const MANAGE_INVOICE_UPLOAD = 'manage-invoice-upload';
export const MAP_INFOBOX_MODAL = 'map-infobox';
export const LOAD_MAP_MODAL = 'load-map-modal';
export const BIN_FORM = 'bin-form';
export const BIN_FORM_V2 = 'bin-form-v2';
export const TMC_ACCESSORIAL_FORM_MODAL = 'TMC_ACCESSORIAL_FORM_MODAL';
export const ACCESSORIAL_REQUEST_FORM_MODAL = 'ACCESSORIAL_REQUEST_FORM_MODAL';
export const REQUEST_CASH_ADVANCE_MODAL = 'request-cash-advance';
export const LOAD_DETAILS_MODAL = 'load-details';
export const RELOAD_DETAILS_MODAL = 'reload-details';
export const LOAD_NOTIFICATION_MODAL = 'load-notification-modal';
export const BOUNCE_WARNING = 'bounce-warning';

export const COUNTRIES_WITH_STATES = ['US', 'CA', 'MX'];

export const PageNames = Object.freeze({
  SET_OKTA_PREFERRED_EMAIL: "Set Okta Preferred Email",
  VERIFY_OKTA_EMAIL_ADDRESS: "Verify Email Address",
  LOGIN_OTP: "Login OTP",
})

export const Cookies = Object.freeze({
  moAuth: "nc_user_auth_flow_2",
  visitorId: "nc_visitor_id",
})

export const ErrorCodes = Object.freeze({
  UNKNOWN: 1,
  AUTH_TOKEN_NOT_FOUND: 2,
})

export const ResponseCodes = Object.freeze({
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
})

export const ModalNames = Object.freeze({
  CAP_TRACKING_SUCCESS_DETAILS: 'cap-tracking-success-details',
  PREFERRED_LANE: 'preferred-lane-modal',
  PREFERRED_LANES_DELETE: 'preferred-lanes-delete',
  Commodities_Modal: 'commodities'
})

export const IS_DEV = process.env.NODE_ENV == 'development' || (['localhost:3000', 'dev.navispherecarrier.com'].includes(window.location.host)) || window.location.host.indexOf('chr8s') > -1;
export const IS_EPHEMERAL = window.location.host.indexOf('chr8s') > -1;
export const IS_DEBUG = process.env.NODE_ENV === 'debug';

export const IS_PROD = process.env.NODE_ENV === 'production' || (!IS_DEV && !IS_EPHEMERAL) && window.location.host === 'navispherecarrier.com';

export const AUTO_OPEN_SCHEDULE_ACTION = "SETBOOKINGRULE";

export const ApprovedCarrierQualificationsStatuses = Object.freeze(["RESTRICTED", "ACTIVE", "PENDING", "NEW"]);